import React from "react"
import { graphql } from "gatsby"
import { theme } from '../../config/website'
import { withPrefix } from 'gatsby'
import Img from "gatsby-image"
import styled from "styled-components"
import SEO from "../components/SEO/SEO"

const Container = styled.div`
  @media (min-width: 800px) {
    display: grid;
    grid-column-gap: 40px;
    grid-template-columns: 3.5fr 6.5fr;
    grid-template-rows: auto;
  }
`

const ImageContainer = styled.div`
  @media (min-width: 800px) {
    position: relative;
    width: 100%;
    align-self: start;

    ::before {
      content: "";
      display: block;
      height: 0;
      width: 0;
      padding-top: 100%;
    }
  }
`

const FeaturedImageContainer = styled.div`
  @media (min-width: 800px) {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`

const FeaturedImage = styled(Img)`
  width: 100%;
  height: 100%;
`

const TextContainer = styled.div`
  align-self: stretch;
`

const Links = styled.div`
  display: flex;
  margin-top: 24px;
`

const Link = styled.a`
  padding: 11px 16px 13px 16px; 
  font-family: Bariol-Bold;
  font-size: 16px;
  color: white;
  margin-right: 8px;
  text-decoration: none;
  background-color: #87b6e9;
  border-radius: 2px;

  :hover {
    text-decoration: underline;
  }
`
Link.defaultProps = {
  theme: {
    foregroundColor: "#ffffff",
    backgroundColor: "#87b6e9"
  }
}

const Title = styled.div`
  h1 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: normal;

    strong {
      font-weight: normal;
      font-family: Bariol-Bold;
    }
  }
  @media (min-width: 800px) {
    h1 {
      margin-top: 0;
    }
  }
`

const Content = styled.div`
  a {
    color: ${theme.primaryColor};
    text-decoration: none;
    font-family: Bariol-Bold;

    :hover {
        text-decoration: underline;
    }
  }
`

const About = ({ data: { prismicAbout, allFile }}) => {

  const staticLinks = [
    {
      url: withPrefix('AshleyArbour_Resume.pdf'),
      target: `_blank`,
      text: "My Resume"
    }
  ]

  const externalLinks = prismicAbout.data.links
      .map(item => {
        return {
          url: item.link.url,
          target: item.link.target,
          text: item.text.text
        }
      })

  const data = {
    image: prismicAbout.data.image.localFile.childImageSharp.fluid,
    titleHTML: prismicAbout.data.title.html,
    contentHTML: prismicAbout.data.content.html,
    links: externalLinks.concat(staticLinks)
  }

  const seo = {
    title: "About"
  }

  return (
    <Container>
      <SEO meta={seo} />
      <ImageContainer>
        <FeaturedImageContainer>
          <FeaturedImage fluid={data.image} />
        </FeaturedImageContainer>
      </ImageContainer>
      <TextContainer>
        <Title dangerouslySetInnerHTML={{ __html: data.titleHTML }} />
        <Content dangerouslySetInnerHTML={{ __html: data.contentHTML }} />
        <Links>
          {data.links.map((link, index) =>
            <Link key={index} href={link.url} target={link.target}>
              {link.text}
            </Link>
          )}
        </Links>
      </TextContainer>
    </Container>
  )
}

export default About

export const pageQuery = graphql`
  query AboutQuery {
    prismicAbout {
	    id
      data {
        title {
          html
        }
        content {
          html
        }
        image {
          localFile {
            childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
                }
            }
          }
        }
        links {
          link {
            url
            target
          }
          text {
            text
          }
        }
      }
    }
  }
`